import React, { Component } from 'react';
import logoViking from './images/svglogo.svg'

import Recipes from "./components/Recipes";
import HomePage from "./components/HomePage";
import Contact from "./components/Contact";
import PhotoGallery from "./components/PhotoGallery";
import {Switch, Route, Link} from 'react-router-dom'
import {toggleClassInBody} from "./lib/js/menuUtils";
import './App.css';

import entete from './images/entete_fumee.jpg'
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isNavbarFixed: false
        }

        this.content = React.createRef()
    }

    componentDidUpdate(prevProps) {

            let topPosition = window.innerWidth < 737 ? 0 : 230
            this.content.current.scrollTo(0, topPosition);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
    }

    handleScroll() {

        const scrollTop = window.scrollY;
        if(scrollTop > 314) {
            this.setState({isNavbarFixed: true})
        } else {
            this.setState({isNavbarFixed: false})
        }
    }

    onClickOnMenu() {

        const width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        if (width < 737) {
            toggleClassInBody('with--sidebar')
        }
    }

  render() {
        let yo = ""
      let display = {
          background: '#c1ad7c',
            backgroundImage: `url(${entete})`,
      }
      if(this.state.isNavbarFixed) {
          yo = "fixed"
          display = {
              backgroundImage: 'none',
              background: '#E6DFC8'
          }
      }

      return (
    <div className="app">
        <header className="app-header">
            <div className="header__icon" id="header__icon"
               onClick={this.onClickOnMenu.bind(this)}>
            </div>
            <div style={display} className="nav-header">
                <div className="nav-brand">
                    <Link to="/">
                        <img className="header-logo" src={logoViking} alt="Le Viking !" />
                    </Link>
                </div>
                <div className="nav-text">La Poêlée Viking</div>
            </div>

            <nav className={"sidenav menu " + yo}>
                <Link to="/" className={"link-no-style header-" + yo}>
                    <img className={"header-logo-" + yo}  src={logoViking} alt="Le Viking !" />
                    <div className={"nav-text-" + yo}>La Poêlée Viking</div>
                </Link>
                <ul className="nav-menu-text">
                    <li><Link to="/" onClick={this.onClickOnMenu.bind(this)}>Notre concept</Link></li>
                    <li><Link to="/les-recettes" onClick={this.onClickOnMenu.bind(this)}>Les recettes</Link></li>
                    <li><Link to="/gallerie-photos" onClick={this.onClickOnMenu.bind(this)}>Photos</Link></li>
                    <li><Link to="/contact" onClick={this.onClickOnMenu.bind(this)}>Devis et Contact</Link></li>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/lapoeleeviking" onClick={this.onClickOnMenu.bind(this)}>
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/lapoeleeviking" onClick={this.onClickOnMenu.bind(this)}>
                            <i className="fab fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </nav>
        </header>

        <div ref={this.content} className="content">
            <Switch>
                <Route exact path='/' component={HomePage}/>
                <Route path='/devis' component={Contact}/>
                <Route path='/les-recettes' component={Recipes}/>
                <Route path='/gallerie-photos' component={PhotoGallery}/>
                <Route path='/contact' component={Contact}/>
            </Switch>
        </div>

        <footer style={{display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "8em"}}>
            <div style={{paddingTop: "1em"}}>
                <i className="far fa-copyright"/>&nbsp;<strong>La Poêlée Viking</strong>
            </div>
            <div style={{textAlign: "center", paddingRight: "1em", paddingLeft: "1em"}}>
                Le viking à la patience de faire bouillir mais pas de laisser refroidir
            </div>
        </footer>

        <div className="site-cache" onClick={this.onClickOnMenu.bind(this)}/>
    </div>
    );
  }
}

export default App;
