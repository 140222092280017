import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import {getAllRecipes} from '../service/recipeService'
import {Helmet} from 'react-helmet'


class RecipeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recipes: []
        }
    }

    componentDidMount() {
        this.setState({recipes: getAllRecipes()})
    }

    renderRecipes(recipes) {

        if (!recipes) return null;

        let result = recipes.map((recipe, i) =>
                <li key={"recipe-" + i} >
                    <Link to={"/les-recettes/" + recipe.link}>
                        <img className="img-responsive u-max-full-width" src={recipe.logo.url}  alt={recipe.logo.alt}/>
                        <div><strong>{recipe.name}</strong></div>
                    </Link>
                </li>
        );
        return <ul className="recipe-list">{result}</ul>
    }

    render() {
        let recipes = this.renderRecipes(this.state.recipes);

        return (<section className="container">
                    <Helmet>
                        <title>Les recettes de votre cuisinier à domiciles</title>
                        <meta name="description" content="Nos multiples recettes composées de produits frais sélectionnés et de mélanges d'épices maison. Le fruit d'années de travail et de recherche." />
                    </Helmet>
                  <div id="recipe-list">
                    <h1>Nos recettes</h1>
                    <p className="mobile-text-center">De délicieuses recettes cuisinées à l'huile d'olive</p>
                    <div className="recipe-list-content">

                        {recipes}

                        <div className="additional-content">
                            <h3 style={{textAlign: "center"}}>Bien plus qu'un traiteur, une véritable animation</h3>
                            <h5 style={{textAlign: "center"}}>En complément nous proposons aussi</h5>
                            <ul>
                                <li>
                                    <strong>Poêlée apéritive de Paella décortiqué</strong>
                                    <span>Pour une entrée ou lors d'un cocktails dinatoire, buffet. Sans déchets.</span>
                                </li>
                                <li>
                                    <strong>Cocktails maison</strong>
                                    <span>La Marquisette et le cocktail de litchis, la Sangria maison.</span>
                                </li>
                                <li>
                                    <strong>Desserts</strong>
                                    <span>Tarte aux fruits de saison ou à la demande</span>
                                </li>
                            </ul>
                            <p className="mobile-text-center">
                                <Link to={{pathname: '/contact'}}>Demandez un devis maintenant</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default RecipeList;
