import React from 'react'
import {withRouter} from "react-router";

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {

        if (this.props.location.pathname !== prevProps.location.pathname) {

            const width = window.innerWidth
                || document.documentElement.clientWidth
                || document.body.clientWidth;

            window.scrollTo(0, width < 737 ? 0 : 230);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);