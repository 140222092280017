import React from 'react';
import ReactDOM from 'react-dom';

import './lib/css/slick.css'
import './lib/css/slick-theme.css'

import './lib/css/normalize.css';
import './lib/css/skeleton.css';
import './skeleton-override.css'

import './index.css';
import './menu.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from "./utils/ScrollToTop";

ReactDOM.render(<BrowserRouter>
    <ScrollToTop><App/></ScrollToTop>
</BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
