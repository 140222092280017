import couscous from '../data/couscous.json'
import paella from '../data/paella_valencia.json'
//import moules from '../data/moules-frites.json'
import tartiflette from '../data/tartiflette.json'
import jambalaya from '../data/jambalaya.json'
import choucroute from '../data/choucroute.json'
//import poeleeAsiatique from '../data/poelee-asisatique.json'
import colomboPoulet from '../data/colombo-poulet.json'
import pouletBasquaise from '../data/poulet-basquaise.json'
//import poeleeVegetarienne from '../data/poelee-vegetarienne.json'
import pouletCurryCoco from '../data/poulet-curry-coco.json'
//import tapas from '../data/tapas.json'
//import cochonDeLait from '../data/cochon-de-lait.json'

const recipes = [couscous, paella, pouletCurryCoco,
    tartiflette, jambalaya, choucroute,
    colomboPoulet, pouletBasquaise];


export function getAllRecipes() {
    return recipes
}

export function getRecipeByName(name) {
    let recipe = recipes.filter(recipe => recipe.link === name)
    if (recipe.length > 0) {
        return recipe[0];
    } else {
        console.log("Recipe " + name + " not found")
        return {}
    }
}
