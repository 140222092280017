import React, {Component} from 'react';

import './Contact.css'
import {Helmet} from "react-helmet";

class ContactPage extends Component {

  computeMailto() {
    return "mailto:" +
      "lapoeleeviking@gmail.com" +
      "?subject=Demande de contact"
  }

  render() {

    let mailTo = this.computeMailto()

    return (
      <section className="container">
        <Helmet>
          <title>Contactez la Poêlée Viking !</title>
          <meta name="description"
                content="Pour plus de réponses à vos éventuelles questions, notre téléphone est à votre disposition."/>
        </Helmet>
        <div id="contact-page">
          <h1>Devis</h1>
          <p>Pour toute demande de devis, n'hesitez pas à nous appeler directement ou à nous envoyer un mail avec les informations ci-dessous :</p>
          <ul>
            <li>Votre nom</li>
            <li>Votre téléphone (Important !)</li>
            <li>La spécialité choisie</li>
            <li>Le nombre de personne</li>
            <li>La date et le lieu de la prestation</li>
          </ul>
          <p>Nous vous répondrons au plus vite.</p>
          <p>Merci.</p>
          <h4>Contact</h4>
          <div className="contact-content">
            <div className="contact-infos">
              <p>Email : <a className="normal-font" href={mailTo}>lapoeleeviking@gmail.com</a></p>
              <p>Téléphone : (+41) 077 233 30 01</p>
            </div>
            {/*<div className="contact-image">*/}
            {/*<img className="img-responsive u-max-full-width" src={leCuistot} alt="Le cuistot viking en train de préparer une paélla" />*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
    );
  }
}

export default ContactPage;
