import React from 'react'
import {Helmet} from "react-helmet";
import Lightbox from 'react-images';


import './PhotoGallery.css'
class PhotoGallery extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
            photos: []
        }

        let galleryUrl = process.env.NODE_ENV !== 'production'
            ? 'http://localhost:3000/gallery'
            : 'https://www.lapoeleeviking.ch/gallery';

        let input = `${galleryUrl}/gallery-description.json`;
        fetch(input, { method: 'GET', mode: 'no-cors'})
            .then((response) => {
                response.json().then((data) => this.setState({photos: data}));
            });


        this.closeLightbox = this.closeLightbox.bind(this)
        this.gotoNext = this.gotoNext.bind(this)
        this.gotoPrevious = this.gotoPrevious.bind(this)
        this.openLightbox = this.openLightbox.bind(this)
    }

    displayGallery(response) {


    }

    closeLightbox() {
        this.setState({lightboxIsOpen: false})
    }

    gotoNext() {
        let newIndex = this.state.currentImage + 1 > this.state.photos.length - 1
            ? this.state.currentImage
            : this.state.currentImage + 1;

        this.setState({currentImage: newIndex})
    }

    gotoPrevious() {

        let newIndex = this.state.currentImage - 1 < 0
            ? this.state.currentImage
            : this.state.currentImage -1;

        this.setState({currentImage: newIndex})
    }

    openLightbox(currentImageIndex) {

        if (window.innerWidth > 737) {
            this.setState({currentImage: currentImageIndex, lightboxIsOpen: true})
        }
    }

    renderGallery(photos) {

          let aa = photos.map((photo, i) => {
                return <div className="gallery-item" key={`photo-${i}`}>
                        <img onClick={this.openLightbox.bind(this, i)}  placeholder={"Salut"} src={photo.src} alt={photo.alt} />
                    </div>
          })

        return <div className="gallery-list">{aa}</div>
    }

    render() {

        let gallery = this.renderGallery(this.state.photos);

        return  <section id="gallery-page">
                    <Helmet>
                        <title>Gallerie photos</title>
                        <meta name="description" content="Pas encore convaincu ? Venez voir nos succulente poêlées en photo." />
                    </Helmet>
                    <div className="gallery-container">
                    <h1>Toutes les photos</h1>
                        {gallery}
                        <Lightbox
                            currentImage={this.state.currentImage}
                            images={this.state.photos}
                            isOpen={this.state.lightboxIsOpen}
                            onClickPrev={this.gotoPrevious}
                            onClickNext={this.gotoNext}
                            onClose={this.closeLightbox}
                        />
                    </div>
                </section>
        }

}

export default PhotoGallery
