import React, {Component} from 'react';

import './Slider.css'
import slickPaellas from "../images/slick_paella.jpg";
import slickTeam from "../images/slick_team.jpg";
import slickSaucisse from "../images/slick_saucisse.jpg";
import slickTartiflettes from "../images/slick_tartiflette.jpg";
import slickJamba from "../images/slick_jamba.jpg";

class SliderContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentSlideIndex: 0,
      slides: [{
        url: slickJamba,
        alt: "Une poêlée de jambalaya et son ananas !"
      }, {
        url: slickTartiflettes,
        alt: "Une fourchette prenant un peu de tartiflette"
      }, {
        url: slickPaellas,
        alt: "Une paêla royale prête à servir"
      }, {
          url: slickSaucisse,
          alt: ""
        },
        {
          url: slickTeam,
          alt: "L'équipe cuisine devant ses poêlées fumantes"
        }]
    }
  }

  componentDidMount() {
    this.start()
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  changeWallpaper(index) {
    if (index != null) {
      this.setState({currentSlideIndex: index})
    } else {
      const nextSlideIndex = this.state.currentSlideIndex !== this.state.slides.length - 1
        ? this.state.currentSlideIndex + 1
        : 0;
      this.setState({currentSlideIndex: nextSlideIndex})
    }
  }

  onBulletClick(index) {
    clearInterval(this.state.intervalId)
    this.changeWallpaper(index)
    this.start()
  }

  start() {
    let intervalId = setInterval(this.changeWallpaper.bind(this), 7000)
    this.setState({intervalId: intervalId})
  }

  buildBullets() {
    return this.state.slides.map((slide, index) => {
      let bulletIcon = this.state.currentSlideIndex === index ? "bullet-dark" : "bullet-light";
      return <div key={`slider-${index}`} onClick={this.onBulletClick.bind(this, index)}
                  className={"pointer circled " + bulletIcon}/>
    })
  }

  render() {

    const bullets = this.buildBullets();
    const slide = this.state.slides[this.state.currentSlideIndex];

    return (
      <div className="slider-container">
        <div style={{position: "relative", width: "100%", height: "100%"}} className="slider-image">
          <img src={slide.url} alt={slide.alt}/>
          <div className="slider-bullet-container">
            {bullets}
          </div>
        </div>

      </div>
    );
  }
}

export default SliderContainer;
