import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import zoneChalandise from '../images/carte_suisse.png'
import poeleeImage from '../images/poelee.jpg'
import paelleomePage from '../images/paella_home_page.jpg'

import './HomePage.css'
import SliderContainer from "./Slider";

class HomePage extends Component {

  render() {

    return (
      <section>
        <div className="title center">
          <h1>Votre traiteur aux poêlées géantes</h1>
        </div>
        <SliderContainer/>
        <div className="container">

          <h2 className="small-title">Le traiteur à domicile, idéal pour une bonne ripaille <br/> La qualité,
            la quantité et le goût !</h2>

          <div className="home-page-section">

            <div className="home-page-image" style={{}}>
              <img className="img-responsive u-max-full-width" alt="Une poêlée fumante"
                   src={poeleeImage}/>
            </div>
            <div className="home-page-text mobile-text-center" style={{}}>
              <span><strong>Quelque soit votre structure :</strong> collectivité, association, professionnel ou particulier, nous saurons nous adapter ! Quelque soit le site, en intérieur comme en extérieur, nous cuisinons en totale autonomie et devant vos convives</span>
            </div>

          </div>

          <div className="home-page-section reverse">
            <div className=" home-page-text" style={{}}>
              <span><strong>Moins cher qu'un traiteur traditionnel</strong>, nous proposons une prestation de qualité avec une organisation parfaitement rodée, pour un prix défiant toute concurrence</span>
            </div>
            <div className=" home-page-image-left" style={{}}>
              <img className="img-responsive u-max-full-width" alt="Une poêlée fumante"
                   src={paelleomePage}/>
            </div>

          </div>
          <div className="home-page-section vertical">
            <h2 className="small-title">La Poêlée Viking, le concept original pour vous simplifier la vie et
              bien manger</h2>
            <div className="home-page-text rows">
              <div><strong>Vous profitez de vos convives</strong> en confiant l’organisation de votre
                repas à un cuisinier professionnel de la restauration de groupe
              </div>
              <div>Une expérience familiale de plus de vingt ans, du grand père au petit fils. <strong>Des
                produits frais</strong> sélectionnés pour vos papilles
              </div>
              <div>Notre équipe se charge du repas et vient <strong>animer et cuisiner</strong> sur le
                lieu de votre réception pour que votre évènement soit ainsi un vrai moment de détente
              </div>
            </div>
            <h2 className="small-title">
              <Link style={{textAlign: "center"}} to={"/les-recettes"}>
                N'hésitez pas : Découvrez nos recettes !
              </Link>
            </h2>

          </div>

          <div className="mobile-only title"><h2>Zone de chalandise</h2></div>
          <div className="home-page-section reverse">
            <div style={{flex: "1.5"}} className="home-page-text-normal">
              <h2 className="mobile-hide">Zone de chalandise</h2>
              <div>Notre Zone de chalandise s'étend sur toute la suisse Romande : Sion, Montreux, Lausanne
                , Neuchâtel, Fribourg et Genève.
              </div>
              {/*<div>Des membres de notre grande famille travaillent également sur le secteur France.</div>*/}
              {/*<div>Nous proposons nos services de traiteur dans les départements de*/}
              {/*    <ul className="list-inline">*/}
              {/*        <li><strong>HAUTE-GARONNE</strong></li>,&nbsp;*/}
              {/*        <li><strong>ARIÈGE</strong></li>,&nbsp;*/}
              {/*        <li><strong>AUDE</strong></li>,&nbsp;*/}
              {/*        <li><strong>TARN</strong></li>,&nbsp;*/}
              {/*        <li><strong>TARN ET GARONNE</strong></li>&nbsp;et&nbsp;*/}
              {/*        <li><strong>GERS</strong></li>*/}
              {/*    </ul>*/}
              {/*</div>*/}
              <div>Pour les demandes en dehors de notre zone de chalandise, n’hésitez pas à <Link
                to="/contact">nous contacter</Link></div>
            </div>
            <div className="home-page-image" style={{flex: 1, paddingLeft: "1em", paddingRight: "1em"}}>
              <img className="img-responsive u-max-full-width" style={{maxWidth: "400px"}}
                   src={zoneChalandise}
                   alt="Carte de la suisse montrant notre zone de chalandise : 200km autour de Lausanne"/>
            </div>
          </div>

        </div>

      </section>
    );
  }
}

export default HomePage;
