import React, { Component } from 'react';
import {getRecipeByName} from '../service/recipeService'
import {Link} from 'react-router-dom'

import './Recipe.css'
import {Helmet} from "react-helmet";

class RecipePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recipe: {}
        }
    }

    componentDidMount() {

        let recipe = getRecipeByName(this.props.match.params.recipeId);
        this.setState({recipe: recipe})
    }

    renderIngredients(ingredients) {

        if(ingredients && ingredients.length > 0) {

            return (<div>
                        <h4>Les ingrédients</h4>
                        <ul>
                            {ingredients.map((ingredient, i) => {
                                return <li itemProp="recipeIngredient" key={`ingredient-${i}`}>{ingredient.name}</li>
                            })}
                        </ul>
                    </div>
            );
        }
    }

    renderOptions(options) {
        if (options) {
            return options.map((option, i) => {
                return <div className="recipe-option" key={`option-${i}`}>{option.description}</div>
            });
        }
    }

    renderItems(items) {
        if (items) {
            return items.map((item, i) => {
                return <div key={`item-menu-${i}`} className="menu-item">{`${item.name} x${item.count}`}</div>
            })
        }
    }

    renderMenus(menus) {
        if (menus) {
            return (<div>
                        <h4>Les formules</h4>
                        {menus.map((menu, i) => {
                            return (
                                <div key={`menu-content-${i}`} className="menu-content">
                                    <div style={{display: "flex"}}>
                                        <div className="menu-name">{menu.name}</div>
                                        <div style={{marginLeft: "2em"}} className="menu-price">{menu.price}</div>
                                    </div>

                                    <div className={"menu-item-list"}>
                                        {this.renderItems(menu.items)}
                                    </div>

                                </div>
                            )
                        })}
                    </div>);


        }
    }

    render() {

        let recipeIngredients = this.renderIngredients(this.state.recipe.ingredients);
        let options = this.renderOptions(this.state.recipe.options)
        let menus = this.renderMenus(this.state.recipe.menus)

        let url = this.state.recipe.presentation ? this.state.recipe.presentation.url : "";
        let alt = this.state.recipe.presentation ? this.state.recipe.presentation.alt : "";

        return (
            <section className="container">
                <Helmet>
                    <title>{this.state.recipe.name}</title>
                    <meta name="description" content={this.state.recipe.htmlDescription} />
                </Helmet>
                <div id="recipe-page" itemScope itemType="http://schema.org/Recipe">
                    <div className="small-screen-only"><h1 itemProp="name">{this.state.recipe.name}</h1></div>
                    <div className="recipe-page-content">
                        <div  className="recipe-page-description">
                            <h1 itemProp="name">{this.state.recipe.name}</h1>
                            <p itemProp="description">{this.state.recipe.description}</p>
                            {menus}
                            {recipeIngredients}
                            {options}
                            <div>
                                <Link to={{
                                    pathname: '/contact',
                                    search: `?recette=${this.state.recipe.link}`
                                }}>Demandez un devis maintenant</Link>
                            </div>
                        </div>
                        <div className="recipe-page-image" style={{paddingTop: '1em' }}>
                            <img itemProp="image" className="recipe-image" src={url} alt={alt}/>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default RecipePage;
