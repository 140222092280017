import React from 'react'
import { Switch, Route } from 'react-router-dom'
import RecipeList from './RecipeList'
import RecipePage from './RecipePage';

const Recipes = () => (
    <Switch>
        <Route exact path='/les-recettes' component={RecipeList}/>
        <Route path='/les-recettes/:recipeId' component={RecipePage}/>
    </Switch>
)


export default Recipes